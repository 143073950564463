import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spin: {
      zIndex: 100,
      color: theme.palette.primary.main,
      fontSize: '50px',
      position: 'fixed',
      top: 'calc(50% - 25px)',
      left: 'calc(50% - 25px)',
      animation: '$spin 1.2s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    absolute: {
      position: 'absolute',
    },
    fixed: {
      position: 'fixed',
    },
  })
);

type LoadingProps = {
  position?: 'fixed' | 'absolute';
};

const Loading: FC<LoadingProps> = ({ position }) => {
  const classes = useStyles();

  return (
    <strong
      className={`c-spinner ${classes.spin} ${classes[position ?? 'fixed']}`}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="progressbar"
    >
      Loading…
    </strong>
  );
};
export default Loading;
