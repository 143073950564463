import React, { FC } from 'react';

export type ButtonProps = {
  children?: React.ReactNode;
  className?: string;
  color: 'primary' | 'secondary' | 'secondary-invert';
  type?: 'button' | 'submit';
  onClick?: () => void;
  form?: string;
  testId?: string;
  disabled?: boolean;
};

const Button: FC<ButtonProps> = ({
  children,
  testId,
  className,
  type,
  color,
  onClick,
  form,
  disabled,
}) => (
  <button
    className={`c-btn c-btn--${color} ${className || ''} ${disabled && ' is-disabled'}`}
    onClick={onClick}
    /* eslint-disable-next-line react/button-has-type */
    type={type || 'button'}
    form={form || undefined}
    data-testid={testId || undefined}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
