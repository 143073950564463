import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const TIME_TOKENS = 'HH:mm:ss';
const DATETIME_TOKENS = `DD/MM/YYYY ${TIME_TOKENS}`;
const INVALID_STR = 'Invalid Date';

export const getLocalTimezone = (value?: string): string => {
  const day = value ? dayjs(value) : dayjs();
  const short = day.local().format('z');
  const long = day.local().format('zzz');
  if (long === 'Coordinated Universal Time') return 'UTC';
  if (long === 'Central European Standard Time') return 'CET';
  if (long === 'Central European Summer Time') return 'CEST';
  return long.match(/\b([a-zA-Z])/g)?.join('') || short;
};

const getFormatTokens = (
  value: string,
  showTimeOnly: boolean,
  showTimezone: boolean,
  showUTC = false
): string => {
  const timezoneToken = showUTC ? '[UTC]' : `[${getLocalTimezone(value)}]`;
  if (showTimeOnly) return showTimezone ? `${TIME_TOKENS} ${timezoneToken}` : TIME_TOKENS;
  return showTimezone ? `${DATETIME_TOKENS} ${timezoneToken}` : DATETIME_TOKENS;
};

export const toLocalFormattedString = (
  value: string,
  showTimezone = true,
  showTimeOnly = false
): string => {
  const utcDay = dayjs.utc(value);
  if (!utcDay.isValid()) return INVALID_STR;

  const formatTokens = getFormatTokens(value, showTimeOnly, showTimezone, false);
  return utcDay.local().format(formatTokens);
};

export const toUTCFormattedString = (
  value: string,
  showTimezone = true,
  showTimeOnly = false
): string => {
  const utcDay = dayjs.utc(value);
  if (!utcDay.isValid()) return INVALID_STR;

  const formatTokens = getFormatTokens(value, showTimeOnly, showTimezone, true);
  return utcDay.format(formatTokens);
};

export const getCurrentDateString = (): string => dayjs().format('YYYY-MM-DD');
