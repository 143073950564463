export const getErrorMessage = (err: any) => ({
  title: 'Error',
  message: err.toString() || 'Server or network error, please try later.',
  variant: 'error',
});

export const getSuccessMessage = (res: any) => ({
  title: 'Success',
  message: res.data || 'Success.',
  variant: 'success',
});
