import React, { lazy, Suspense, useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Auth } from 'aws-amplify';
import Loading from './components/common/Loading';

import Layout from './components/Layout';
import useAssignedRole from './hooks/useAssignedRole';
import TaskSubmissions from './components/admin-dashboard/TaskSubmissions';

const TaskPage = lazy(() => import('./pages/TaskPage'));
const NotFound = lazy(() => import('./pages/NotFound'));
const TaskListPage = lazy(() => import('./pages/TaskListPage'));
const AdminDashboardPage = lazy(() => import('./pages/AdminDashboardPage'));

const Main = () => {
  const { isAdmin, isViewOnly } = useAssignedRole();
  const { user, getIdTokenClaims } = useAuth0();
  const [userId, setUserId] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();

  useEffect(() => {
    const federatedIdentity = async () => {
      const identity = localStorage.getItem('aws-amplify-federatedInfo');
      if (!identity) {
        const claims = await getIdTokenClaims();
        if (!claims) {
          return;
        }
        // eslint-disable-next-line no-underscore-dangle
        const idToken = claims.__raw;
        const identity = await Auth.federatedSignIn(
          'dev-skyuk.eu.auth0.com',
          {
            token: idToken,
            expires_at: claims?.exp! * 1000,
          },
          {
            name: claims.name!,
            email: claims.email,
          }
        );
        setUserId(identity.identityId);
        setUserEmail(claims.email);
      } else {
        const identityObject = JSON.parse(identity);
        setUserId(identityObject.user.id);
        setUserEmail(identityObject.user.email);
      }
    };
    federatedIdentity();
  });

  return (
    <Suspense fallback={<Loading />}>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <Layout userName={userEmail}>
            <Switch>
              <Route exact path="/">
                <TaskListPage userId={userId!} />
              </Route>
              <Route exact path="/task/:id">
                <TaskPage userId={userId!} userSub={user?.sub!} />
              </Route>
              {(isAdmin || isViewOnly) && (
                <Route exact path="/admin/dashboard">
                  <AdminDashboardPage />
                </Route>
              )}
              {(isAdmin || isViewOnly) && (
                <Route exact path="/admin/submissions/task/:id">
                  <TaskSubmissions />
                </Route>
              )}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </SnackbarProvider>
    </Suspense>
  );
};

export default Main;
