import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const useAssignedRole = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);

  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    async function loadGroups() {
      const claims = await getIdTokenClaims();
      if (claims) {
        const roles = claims['https://sky.com/roles'] ?? [];
        setIsAdmin(roles.includes('Admin'));
        setIsViewOnly(roles.includes('View Only'));
      }
    }
    loadGroups();
  }, [getIdTokenClaims]);
  return { isAdmin, isViewOnly };
};

export default useAssignedRole;
