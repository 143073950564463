import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import VideoJs from 'video.js';
import Loading from './common/Loading';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
  })
);

const videoJsOptions = {
  controls: true,
  autoplay: false,
  fluid: false,
  loop: false,
  width: 100,
  aspectRatio: '16:9',
};

type VideoPlayerProps = {
  fileKey: string;
  fileType: string;
};

const VideoPlayer = ({ fileKey, fileType }: VideoPlayerProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const video = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const player = VideoJs(video.current, videoJsOptions, async () => {
      setLoading(true);
      const url = await Storage.get(fileKey, {
        donwload: true,
        customPrefix: {
          public: '',
        },
      });
      player.src({ src: url as string, type: fileType });
      setLoading(false);
    });

    return () => player.dispose();
  }, [fileKey, fileType]);

  return (
    <div className={classes.root}>
      {loading && <Loading position="absolute" />}
      <div data-vjs-player>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video className="video-js" ref={video} />
      </div>
    </div>
  );
};

export default VideoPlayer;
