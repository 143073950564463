import { CardMedia, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import placeholderImage from '../../placeholder.jpeg';

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      position: 'relative',
      height: 220,
    },
  })
);

type ThumbnailProps = {
  s3Key: string;
};

const Thumbnail = ({ s3Key }: ThumbnailProps) => {
  const classes = useStyles();
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();

  useEffect(() => {
    const getThumbnailUrl = async () => {
      if (!s3Key) {
        return;
      }
      const url = (await Storage.get(s3Key.replace('public/', ''), { download: false })) as string;
      setThumbnailUrl(url as string);
    };
    getThumbnailUrl();
  }, [s3Key]);

  return <CardMedia className={classes.media} image={thumbnailUrl || placeholderImage} />;
};

export default Thumbnail;
