import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/main.scss';
import createTheme from './theme';

import './index.css';

const { REACT_APP_REDIRECT_URL, REACT_APP_AUTH_0_DOMAIN, REACT_APP_AUTH_0_CLIENT_ID } = process.env;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const IndexComponent = () => {
  const history = useHistory();
  const query = useQuery();
  const isCherry = !!query.get('isCherry');
  const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const prefersDarkMode = isCherry || isDarkMode;

  const onRedirectCallback = (appState: AppState | undefined) => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : (window.location.href = REACT_APP_REDIRECT_URL!)
    );
  };

  const theme = React.useMemo(() => createTheme(prefersDarkMode), [prefersDarkMode]);

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH_0_DOMAIN!}
      clientId={REACT_APP_AUTH_0_CLIENT_ID!}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Auth0Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <IndexComponent />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
