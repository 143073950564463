import Container from '@material-ui/core/Container';
import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Divider, Drawer, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { getErrorMessage } from '../../utils/messages';
import Loading from '../common/Loading';
import TaskUserSubmissions from '../TaskUserSubmissions';
import {
  SubmissionSource,
  useDeleteUserSubmissionMutation,
  useListTaskSubmissionsQuery,
  UserSubmission,
} from '../../API';
import useLocalStorage from '../../hooks/useLocalStorage';
import Button from '../sky/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(3),
      flex: 1,
      display: 'flex',
    },
    title: {
      paddingTop: theme.spacing(3),
    },
    titleSection: {
      justifyContent: 'space-between',
    },
    showFiltersButton: {
      marginLeft: theme.spacing(3),
      position: 'relative',
      top: theme.spacing(5),
    },
    loadMoreButton: {
      margin: `${theme.spacing(3)}px auto`,
      display: 'flex',
    },
    switchesWrapper: {
      padding: theme.spacing(3),
      flex: 1,
      display: 'flex',
    },
    divider: {
      marginBottom: theme.spacing(3),
    },
  })
);

const TaskSubmissions = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { id: taskId } = useParams<Record<string, string>>();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteUserSubmission] = useDeleteUserSubmissionMutation();

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [hideWebSubmissions, setHideWebSubmissions] = useLocalStorage<boolean>(
    'hideWebSubmissions',
    false
  );
  const [hideCherrySubmissions, setHideCherrySubmissions] = useLocalStorage<boolean>(
    'hideCherrySubmissions',
    false
  );

  const handleError = (err: any) => {
    const { message, variant }: any = getErrorMessage(err);
    enqueueSnackbar(message, { variant });
  };

  const { data, loading, error, fetchMore } = useListTaskSubmissionsQuery({
    variables: { taskId },
  });

  let taskSubmissions = data?.listTaskSubmissions?.items || [];

  const handleLoadMore = async (nextToken: string) => {
    if (nextToken) {
      await fetchMore({
        variables: { taskId, nextToken },
      });
    }
  };

  const numOfWebSubmissions = taskSubmissions.filter((x) => x?.metadata.source === 'WEB').length;
  const numOfCherrySubmissions = taskSubmissions.filter(
    (x) => x?.metadata.source === 'CHERRY'
  ).length;

  const isMultipleSources = numOfCherrySubmissions !== 0 && numOfWebSubmissions !== 0;

  if ((hideWebSubmissions || hideCherrySubmissions) && taskSubmissions.length > 0) {
    taskSubmissions = taskSubmissions.filter((taskSubmission) => {
      if (hideWebSubmissions && taskSubmission?.metadata.source === SubmissionSource.Web)
        return false;
      if (hideCherrySubmissions && taskSubmission?.metadata.source === SubmissionSource.Cherry)
        return false;
      return true;
    });
  }

  if (error) {
    const { message, variant }: any = getErrorMessage(error);
    enqueueSnackbar(message, { variant });
    push('/');
  }

  const handleDeleteSubmission = async (submissionPk: string, submissionSk: string) => {
    try {
      await deleteUserSubmission({ variables: { pk: submissionPk, sk: submissionSk } });
      fetchMore({
        variables: { taskId },
      });
      enqueueSnackbar(`Submission was deleted`, { variant: 'success' });
    } catch (err) {
      handleError(err);
    }
  };

  const filterSwitches = () => (
    <FormGroup row className={classes.switchesWrapper}>
      <FormControlLabel
        control={
          <Switch
            checked={hideWebSubmissions}
            onChange={(evt) => setHideWebSubmissions(evt.target.checked)}
            name="hideWebSubmissions"
            color="primary"
          />
        }
        label="Hide Web Submissions"
      />
      <FormControlLabel
        control={
          <Switch
            checked={hideCherrySubmissions}
            onChange={(evt) => setHideCherrySubmissions(evt.target.checked)}
            name="hideCherrySubmissions"
            color="primary"
          />
        }
        label="Hide Cherry Submissions"
      />
    </FormGroup>
  );

  if (loading) return <Loading />;

  return (
    <>
      <FormGroup row className={classes.titleSection}>
        <Typography className={classes.title} component="h1" variant="h3" gutterBottom>
          Task Submissions
        </Typography>
        {isMultipleSources && (
          <React.Fragment key="top">
            <Button
              color="primary"
              className={classes.showFiltersButton}
              onClick={() => setShowFilters(!showFilters)}
            >
              Show Filters
            </Button>
            <Drawer anchor="top" open={showFilters} onClose={() => setShowFilters(false)}>
              {filterSwitches()}
            </Drawer>
          </React.Fragment>
        )}
      </FormGroup>
      <Typography className={classes.title} component="h6" variant="h6" gutterBottom>
        Task ID: {taskId}
      </Typography>

      <Container className={classes.content}>
        <TaskUserSubmissions
          deleteSubmission={handleDeleteSubmission}
          userSubmissions={taskSubmissions ? (taskSubmissions as UserSubmission[]) : []}
          loading={false}
        />
      </Container>
      <Divider className={classes.divider} />
      <Button
        className={classes.loadMoreButton}
        color="primary"
        disabled={!data?.listTaskSubmissions?.nextToken}
        onClick={() => handleLoadMore(data?.listTaskSubmissions?.nextToken ?? '')}
      >
        Load more
      </Button>
    </>
  );
};

export default TaskSubmissions;
